import { useState, useEffect } from 'react';
import resume from '../content/cd-smith-resume.pdf';
import logo from "../content/cd-logo-2.jpg";

const AboutMe = () => {
	const styles = {
		icon: {
			textDecoration: 'none',
			fontSize: '62px',
			padding: '10px',
			transition: '0.2s ease-in'
		}
	};

	const [downloading, setDownloading] = useState(false);

	useEffect(() => {
		setDownloading(false);
	}, [downloading]);

	const handleDownload = () => {
		setDownloading(true);
		const link = document.createElement('a');
		link.href = resume;
		link.download = 'CD-Smith-Resume.pdf';
		link.onload = () => {
			link.remove();
			setDownloading(false);
		};
		document.body.appendChild(link);
		link.click();
	};

	return (
		<>
			<header className="header">
				<img className="logo" src={logo} alt="logo" />
			</header>
			<section className='about'>
				<h3 className='pageTitle'>CD Smith</h3>
				<p className='pageDescription'>.NET Developer</p>
				<div className='aboutContainer container'>
					<div className='row'>
						<div className='contentContainer'>
							<h4>Nice to meet you!</h4>
							<h5>I love building .NET applications!</h5>
							<div className='contentDescription'>
								<h4>Technical Skills</h4>
								<ul>
									<li>.NET Core and .NET Framework with C#</li>
									<li>MS SQL Server</li>
									<li>TypeScript</li>
									<li>React</li>
									<li>Angular</li>
									<li>Html, CSS, JSON</li>
									<li>Azure App Service, Functions, Static Web Sites and SQL</li>
								</ul>
								<br />
							</div>
							<div className='contentDescription'>
								<h4>Strong Opinions, Loosely Held</h4>
								<p>
									I've been developing with Microsoft technologies for over 20 years
									and have had the opportunity to lead teams to create fantastic systems.
									This is what I believe works the best so far, you're welcome to 
									change my mind!
									<br />
									<ul>
										<li>- Strict coding standards</li>
										<li>- Keeping up with best practices</li>
										<li>- Clean code and architecture</li>
										<li>- Using the right tool</li>
										<li>- Agile teams with scrum</li>
										<li>- SOLID Principles</li>
										<li>- Test-driven development</li>
									</ul>
								</p>
								<br />
								<p>
									I believe that successful development happens with an
									opinionated architecture and by following strict standards,
									conventions, and best practices.
								</p>
								<p>
									This approach saves time and money by minimizing bugs up front
									and reduces technical debt before it can start.
								</p>
								<br />
							</div>
							<div className='contentDescription'>
								<h4>Certifications</h4>
								<ul>
									<li>MCPD</li>
									<li>MCTS</li>
									<li>MCSA</li>
								</ul>
							</div>
							<div className='infoContainer'>
								<div className='row'>
									<div className='col-12 col-md-6 info'>
										<span>Location:</span>
										<p>Tampa, FL</p>
									</div>
									<div className='col-12 col-md-6 info'>
										<span>Goals:</span>
										<p>Remote architect, lead, and senior developer roles</p>
									</div>
								</div>
								<div className='row'>
									<div className='col-12 col-md-6 info'>
										<span>Email:</span>
										<p>
											<a href='mailto:cdsmith@outlook.com'>
												cdsmith@outlook.com
											</a>
										</p>
									</div>
									<div className='col-12 col-md-6 info'>
										<span>Availability:</span>
										<p>Open To New Opportunities</p>
									</div>
								</div>
							</div>
							<div className='buttonContainer'>
								<button
									className='btn downloadCV'
									onClick={handleDownload}
									disabled={downloading}
								>
									{downloading ? 'Downloading...' : 'Download Resume'}
								</button>{' '}
							</div>
						</div>
					</div>
				</div>
				<div className='socialIcons' style={styles.socialIcons}>
					<a
						className='icon'
						style={styles.icon}
						href='https://www.linkedin.com/in/cdsmith72/'
					>
						<i
							className='fa-brands fa-linkedin'
							aria-hidden='true'
							title="CD Smith's LinkedIn Profile"
						></i>
					</a>
				</div>
			</section>
		</>
	);
};

export default AboutMe;
