import AboutMe from './components/Home';

function App() {

	return (
      <AboutMe />
	);
}

export default App;
